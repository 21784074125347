import React from 'react';
import styled from 'styled-components';
import SEO from '../components/organisms/seo';

const NotFoundPage = () => (
  <>
    <SEO siteTitle="404" />
    <Styled404>
      <p className="hl">Oh no! This page doesn't exist.</p>
    </Styled404>
  </>
);

const Styled404 = styled.div`
  padding: var(--sp-1-8);
  min-height: 100vh;
`;

export default NotFoundPage;
